import { Link } from "react-router-dom";
import { FooterContentProps } from "@ultracommerce/react-storefront/global/src/components/Footer/FooterContent";
import { useElementContext } from "@ultracommerce/react-storefront/global";

export const FooterStyleThree = ({ logo, theme, ribbonMenu, formElement }: FooterContentProps) => {
  const {
    CommonModule: { PoweredBy },
  } = useElementContext();
 
  return (
    <>
      <div className="container d-flex justify-s-b pt-5 px-2 flex-wrap">
        <div className="pt-3 col-12 col-md-6">
          {theme?.footerLogoTarget !== "None" && (
            <Link className="col-md-3 col-sm-12 d-block" to="/">
              <span className="navbar-brand d-block">
                <img
                  src={`${logo?.trim()}`}
                  alt=""
                  className="img-fluid navbar-brandLogo"
                  style={{
                    maxHeight: "60px",
                    minWidth: "150px",
                    marginBottom: "1em",
                  }}
                />
              </span>
            </Link>
          )}
          <div dangerouslySetInnerHTML={{ __html: theme.FooterAddress }} />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center align-self-center">
          {formElement ? formElement : null}
        </div>
      </div>
      <div className="footer-ribbon footer-ribbon-three p-0 flex-wrap">
        <div className="container py-4 px-0 d-flex justify-content-between align-items-center align-items-sm-start flex-md-row flex-column">
          {ribbonMenu}
          <div className="d-flex footer-copyright-powered">
          <p className="m-0 mx-2 align-self-center">{theme?.copyRight}</p>
          <PoweredBy styleList={{ height: "25px", width: "fit-content" }} theme={theme} />
        </div>
        </div>
      </div>
    </>
  );
};
