import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import {
  ComponentContextProvider,
  ElementContextProvider,
  ServiceContextProvider,
  AppSwitcher,
  initApp,
  CommonModule,
  PageModule,
} from "@ultracommerce/react-storefront/global";
import "./i18n";
import store from "./createStore";
import "./assets/theme";
import preload from "./preload";
import { FooterStyleThree } from "./components/Footer/Footer-style-three";
import ProductDetailPage from "./pages/ProductDetailsPage/ProductDetailsPage.page";

initApp(preload);

CommonModule.FooterStyleThree = FooterStyleThree
PageModule.ProductDetailsPage = ProductDetailPage

createRoot(document.getElementById("app")!).render(
  <Provider store={store}>
    <Router>
    <ServiceContextProvider>
      <ElementContextProvider customComponents={{ CommonModule, PageModule }}>
          <ComponentContextProvider>
            <AppSwitcher />
          </ComponentContextProvider>
        </ElementContextProvider>
      </ServiceContextProvider>
    </Router>
  </Provider>
);
